import { ListStore } from 'storwork-stores'

let store = null
let interval = null

export default () => {
  if (!store) {
    store = new ListStore({type: 'client', staticFilters: {
      order: 'id asc'
    }})
    interval = setInterval(store.loadRecords, 2000)
  }
  return store
}
