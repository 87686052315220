import React, { Component } from 'react';
import {StorworkInit, B2BStore, JWT} from 'storwork-stores'
import {observer} from 'mobx-react'
import _ from 'lodash'
import getStore from './Store'

const filterReaction = reaction => el => el.information.some(inf => JSON.parse(inf.content).content === reaction)
const howManyReaction = (records, reaction) => records.filter(filterReaction(reaction)).length
const aggregateReactions = records => ({
    cool: howManyReaction(records, 'cool'),
    good: howManyReaction(records, 'good'),
    boring: howManyReaction(records, 'boring'),
    toofast: howManyReaction(records, 'toofast'),
})

const logout = () => {
  JWT.forget()
  window.location.assign('/')
}

const Application = observer(class _Application extends React.Component {
  render () {
    let records = this.props.store.records
    return  <div> <a class="button is-link" onClick= {() => logout()}>Logout</a>
        <nav class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Cool</p>
            <p class="title">{howManyReaction(records, 'cool')}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Good</p>
            <p class="title">{howManyReaction(records, 'good')}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Boring</p>
            <p class="title">{howManyReaction(records, 'boring')}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Too fast</p>
            <p class="title">{howManyReaction(records, 'toofast')}</p>
          </div>
        </div>
      </nav>
    </div>
}})


const ApplicationWithStore = props => <Application store={getStore()} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<ApplicationWithStore />} />
  }
}

export default App;
